import { Player } from 'Model';
import { PlayerImage } from './PlayerImage';

export type PlayerCardProps = {
  player?: Player;
  primaryText: string;
  secondaryText?: string;
  avatarId: string;
  descriptionText?: string;
  showLabel?: boolean;
  labelText?: string;
  bgOuterColorClass?: string;
  bgInteriorColorClass?: string;
  avatarBorderColorClass?: string;
};

export default function PlayerCard(props: PlayerCardProps) {
  const {
    player,
    primaryText,
    secondaryText,
    avatarId,
    descriptionText,
    showLabel = false,
    labelText = 'Player',
    bgOuterColorClass = 'bg-gray-700',
    bgInteriorColorClass = 'bg-gray-800',
    avatarBorderColorClass = 'border-gray-600'
  } = props;
  return (
    <div>
      {showLabel && <div className="font-bold text-lg text-gray-100 mb-3">{labelText}</div>}
      <div className={bgOuterColorClass + '  rounded-xl w-full lg:w-80 noiseBackground'}>
        <div className={bgInteriorColorClass + ' ' + avatarBorderColorClass + ' p-6 rounded-xl w-full border '}>
          <div className="text-center text-gray-50 text-3xl font-medium mb-6">{primaryText}</div>
          <div className="mb-6">
            <div className={'h-48 w-48 bg-white mx-auto rounded-full overflow-hidden border-8 ' + avatarBorderColorClass + ' ' + (player?.hasImageSrc ? 'p-0' : 'p-3')}>
              {player ? <PlayerImage name={player.name} hasImageSrc={player.hasImageSrc} avatarId={player.avatarId} classes={''} /> : <img alt="avatar" src={`/${avatarId}.png`} />}
            </div>
          </div>
          {secondaryText && <div className="text-center text-lg font-medium text-gray-300">{secondaryText}</div>}
          {descriptionText && <div className="mt-3 text-gray-400 text-center" dangerouslySetInnerHTML={{ __html: descriptionText }} />}
        </div>
      </div>
    </div>
  );
}
