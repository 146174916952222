export default function GhostIcon() {
  return (
    <div>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1200 1200"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinejoin: 'round',
          strokeMiterlimit: 2
        }}
      >
        <path
          d="M602.28,1024.3C506.464,1028.86 438.02,1074.49 392.39,1065.37C246.38,1033.43 278.32,914.796 127.75,933.046C68.434,928.484 13.68,905.671 -0.01,841.792C369.57,686.662 278.32,408.332 346.76,253.202C415.201,93.502 784.78,93.502 853.22,253.202C926.224,408.332 830.408,686.662 1199.99,841.792C1186.3,905.671 1131.55,928.483 1072.23,933.046C921.66,914.796 953.6,1033.43 807.59,1065.37C761.961,1074.49 693.52,1028.87 602.27,1024.3L602.28,1024.3Z"
          style={{
            fill: 'white'
          }}
        />
      </svg>
    </div>
  );
}
