export default function NightGhostExplainer() {
  return (
    <div>
      <div className="font-bold text-lg text-gray-100 mb-3">Explainer</div>
      <div className="bg-gray-800 border border-gray-700 p-6 rounded-xl">
        <div className="text-2xl font-medium text-gray-100 mb-3">Hey, Ghost!</div>
        <div className="text-gray-100 mb-3">Click to haunt a survivor!</div>
        <div className="text-gray-100 mb-3">The more you click, the more attention you draw towards them.</div>
      </div>
    </div>
  );
}
