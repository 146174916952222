export default function HeartIcon() {
  return (
    <div>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" fill="currentColor" stroke="currentColor">
        <switch>
          <foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"></foreignObject>
          <g>
            <g>
              <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"></path>
              <path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"></path>
              <path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"></path>
              <path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"></path>
            </g>
            <path d="M86.3,13.5C76.6,7,63.4,8.9,55.2,17.2L50,22.4l-5.6-5.6c-10.8-10.8-29-9.4-37.9,4c-6.5,9.8-4.6,22.9,3.7,31.2l37.5,37.5    c1.3,1.3,3.4,1.3,4.7,0l38-38C101.1,40.7,99.7,22.4,86.3,13.5z M63.8,54.4c0,0.8-0.7,1.5-1.5,1.5h-7.2v7.2c0,0.8-0.7,1.5-1.5,1.5    h-7.2c-0.8,0-1.5-0.7-1.5-1.5v-7.2h-7.2c-0.8,0-1.5-0.7-1.5-1.5v-7.2c0-0.8,0.7-1.5,1.5-1.5h7.2v-7.2c0-0.8,0.7-1.5,1.5-1.5h7.2    c0.8,0,1.5,0.7,1.5,1.5v7.2h7.2c0.8,0,1.5,0.7,1.5,1.5V54.4z"></path>
          </g>
        </switch>
      </svg>
    </div>
  );
}
