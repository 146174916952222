import GuessIcon from 'assets/GuessIcon';

export default function NightSeerExplainer() {
  return (
    <div>
      <div className="font-bold text-lg text-gray-100 mb-3">Explainer</div>
      <div className="bg-gray-800 border border-gray-700 p-6 rounded-xl">
        <div className="text-2xl font-medium text-gray-100 mb-3">Hello, Seer!</div>
        <div className="text-gray-100 mb-6">Night has fallen and that means it's time to guess who you think the werewolf might be!</div>
        <div className="p-3 rounded-xl bg-gray-700 mb-6 text-center">
          <div className="text-gray-300 font-medium">To make your selection, click on a player in the "Survivors" list.</div>
          <div className="text-gray-300 font-medium mt-1.5">
            A
            <div className="inline-block h-6 w-6 mx-3">
              <GuessIcon />
            </div>
            icon will be added to indicate your selection.
          </div>
        </div>
        <div className="text-gray-100 mb-3">You can make 1 guess each turn and the result of your guess will be revealed at day break.</div>
        <div className="text-gray-100 mb-3">
          Use this new found information to guide the villagers deliberations before voting, but don't reveal yourself or they will surely exile you to the wilderness!
        </div>
      </div>
    </div>
  );
}
