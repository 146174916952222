import { useContext, useState } from 'react';
import { GamePhase } from 'Model';
import { getPlayerByName, getWerewolves, isPlayerSeer } from 'Util';
import { PlayerCard, SpecialPlayers, Button, PlayerList, GameUIContainer } from 'components/shared';
import { GameContext } from 'GameContext';

export type DayPhaseProps = {
  handleNextPhase: Function;
};

export default function DayPhase(props: DayPhaseProps) {
  const { handleNextPhase } = props;
  const { gameState, name } = useContext(GameContext);
  const [open, setOpen] = useState(true);

  if (gameState && name) {
    gameState.currentState.gameRounds.sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : -1;
    });
    let currentRound = gameState.currentState.gameRounds[0];

    let newlyDeceased = undefined;
    if (currentRound.werewolfVictim !== currentRound.witchGuarded) {
      newlyDeceased = gameState.currentState.players.find((player) => {
        return player.name === currentRound.werewolfVictim;
      });
    }

    const isGameMaster = name === gameState.gameMaster;
    const isSeer: boolean = isPlayerSeer(name, gameState.currentState.players);
    const werewolves = getWerewolves(gameState);

    const seerGuessCorrect =
      werewolves.find((werewolf) => {
        return werewolf.name === currentRound.seerGuess;
      }) !== undefined;

    const seerGuessPlayer = getPlayerByName(gameState, currentRound.seerGuess ?? '');

    const currentPlayer = getPlayerByName(gameState, name);

    let nextPhase = gameState.currentState.finalBattle ? GamePhase.FINAL_BATTLE : GamePhase.VOTING;
    let nextButtonText = gameState.currentState.finalBattle ? 'Start Final Battle' : 'Start Voting';

    return (
      <div>
        <GameUIContainer
          headerText="Day Break"
          subHeaderText="What fresh horrors await the townspeople?"
          headerButton={isGameMaster && <Button buttonText={nextButtonText} onClick={() => handleNextPhase(nextPhase)} fullWidth={true} />}
          isNight={false}
          openCardOverlay={open}
          onCloseCardOverlay={() => {
            if (isGameMaster) {
              setOpen(false);
            }
          }}
          overlayContent={
            <div className="sm:flex gap-12">
              {isSeer && currentRound.seerGuess && (
                <div className="mb-6 sm:mb-0">
                  <PlayerCard
                    player={seerGuessPlayer}
                    primaryText={seerGuessCorrect ? `${currentRound.seerGuess} is a werewolf!` : `${currentRound.seerGuess} is not a werewolf!`}
                    avatarId={seerGuessPlayer ? seerGuessPlayer.avatarId : ''}
                    descriptionText={`<div class="text-center">Use this new found information to guide the villagers conversation.</div>`}
                    showLabel={false}
                  />
                </div>
              )}
              {newlyDeceased && (
                <PlayerCard
                  player={newlyDeceased}
                  primaryText="Tragedy has struck!"
                  secondaryText={newlyDeceased ? newlyDeceased.name : ''}
                  avatarId={newlyDeceased ? newlyDeceased.avatarId : ''}
                  descriptionText={`<div class="text-center">The Werewolf has taken our good friend ${newlyDeceased ? newlyDeceased.name : ''}!</div>`}
                  showLabel={false}
                />
              )}
              {!newlyDeceased && (
                <PlayerCard
                  primaryText="A new day has dawned!"
                  avatarId="spring"
                  descriptionText='<div class="text-center">Fortune shines on the village! No one has been taken from us this night</div>'
                  showLabel={false}
                />
              )}
            </div>
          }
          leftColumn={
            <div>
              {currentPlayer && (
                <PlayerCard
                  player={currentPlayer}
                  primaryText={currentPlayer.name}
                  secondaryText={currentPlayer.role}
                  avatarId={currentPlayer.avatarId}
                  descriptionText={`<div class="text-center">${currentPlayer.roleDescription}</div>`}
                  showLabel={true}
                />
              )}
              {isGameMaster && <SpecialPlayers gameState={gameState} />}
            </div>
          }
          rightColumn={<PlayerList gameState={gameState} onPlayerSelect={() => {}} filterCurrentPlayer={false} name={name} />}
        />
      </div>
    );
  } else {
    return <div />;
  }
}
