import { Tally } from 'Model';
import { getCurrentRound, getPlayerByName } from 'Util';
import { PlayerCard, SpecialPlayers, Button, PlayerList, GameUIContainer } from 'components/shared';
import { DeadState } from 'components/phases';
import { GameContext } from 'GameContext';
import { useContext } from 'react';

export type VotingPhaseProps = {
  handleSubmitVote: Function;
  handleEndVoting: Function;
};

export default function VotingPhase(props: VotingPhaseProps) {
  const { gameState, name } = useContext(GameContext)!;

  if (gameState && name) {
    const { handleSubmitVote, handleEndVoting } = props;

    const isGameMaster = name === gameState.gameMaster;

    let player = gameState.currentState.players.find((player) => {
      return name === player.name;
    });
    let currentRound = getCurrentRound(gameState);
    let votes = currentRound.votes;
    let totalTally: Tally[] = [];
    votes.forEach((vote) => {
      let existingTally = totalTally.find((t) => {
        return t.guessName === vote.guessName;
      });
      if (existingTally) {
        existingTally.totalVotes += 1;
      } else {
        totalTally.push({
          guessName: vote.guessName,
          totalVotes: 1
        });
      }
    });

    return (
      <div>
        {isGameMaster || player?.isAlive ? (
          <GameUIContainer
            headerText="Time to vote!"
            subHeaderText="Who do you think the werewolf is?"
            headerButton={isGameMaster && <Button buttonText="End Voting" onClick={handleEndVoting} fullWidth={true} />}
            isNight={false}
            leftColumn={
              <div>
                {player && (
                  <PlayerCard
                    player={player}
                    primaryText={player.name}
                    secondaryText={player.role}
                    avatarId={player.avatarId}
                    descriptionText={`<div class="text-center">${player.roleDescription}</div>`}
                    showLabel={true}
                  />
                )}
                {isGameMaster && <SpecialPlayers gameState={gameState} />}
              </div>
            }
            centerColumn={
              <div>
                {player && (
                  <div className="mb-6 sm:mb-0">
                    <div className="font-bold text-lg text-gray-100 mb-3">Action</div>
                    <div className="text-center font-bold text-xl bg-red-500 rounded-xl p-6">Click on a villager you think is the werewolf. They will be exiled from the village.</div>
                  </div>
                )}
                {totalTally.length > 0 && isGameMaster && (
                  <div className="mb-6 sm:mb-0">
                    <div className="mb-3 font-bold text-lg text-gray-100">Vote Results</div>
                    <div className="grid grid-cols-1 gap-6">
                      {totalTally.map((tally, index) => (
                        <div key={`${tally.guessName}_${index}`} className="bg-gray-800 border border-gray-600 px-6 py-3 rounded-xl flex justify-between gap-12">
                          <div className="flex">
                            <div className="rounded-full overflow-hidden mr-3 border border-4 border-gray-400 bg-white">
                              <img alt="avatar" src={`/${getPlayerByName(gameState, tally.guessName)?.avatarId}.png`} className="h-12 w-12" />
                            </div>
                            <div className="self-center text-left mr-3">
                              <div className="font-bold text-gray-300">{tally.guessName}</div>
                            </div>
                          </div>
                          <div className="self-center text-4xl font-bold text-gray-300">{tally.totalVotes}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            }
            rightColumn={<PlayerList gameState={gameState} onPlayerSelect={handleSubmitVote} filterCurrentPlayer={true} name={name} />}
          />
        ) : (
          <DeadState gameState={gameState} name={name} />
        )}
      </div>
    );
  } else {
    return <div />;
  }
}
