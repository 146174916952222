import SkullIcon from 'assets/SkullIcon';

export default function NightVillagerExplainer() {
  return (
    <div>
      <div className="font-bold text-lg text-gray-100 mb-3">Explainer</div>
      <div className="bg-gray-800 border border-gray-700 p-6 rounded-xl">
        <div className="text-2xl font-medium text-gray-100 mb-3">Beware, Villager!</div>
        <div className="text-gray-100 mb-3">Night has fallen and the werewolf is on the prowl.</div>
        <div className="text-gray-100 mb-3">All you can do is hope for the best when the new day dawns.</div>
      </div>
    </div>
  );
}
