import { Dialog, Menu, Transition } from '@headlessui/react';
import { CameraIcon, CheckIcon, DotsVerticalIcon } from '@heroicons/react/solid';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

export type PlayerSetupDialogProps = {
  open: boolean;
  setOpen: Function;
  onSave: Function;
};

export default function PlayerSetupDialog(props: PlayerSetupDialogProps) {
  const { open, setOpen, onSave } = props;
  const [name, setName] = useState<string | undefined>();
  const [avatarId, setAvatarId] = useState('1');
  const [openAvatarDialog, setOpenAvatarDialog] = useState(false);
  const [openWebcamDialog, setOpenWebcamDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  let avatarIds = [];
  for (let i = 1; i <= 20; ++i) {
    avatarIds.push(i);
  }

  const handleOnSave = () => {
    onSave(name, avatarId, imageSrc);
  };

  const webcamRef = useRef<any | undefined>();
  const [deviceId, setDeviceId] = useState(localStorage.hasOwnProperty('selectedCamera') ? localStorage.getItem('selectedCamera')! : '');
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const videoConstraints = {
    width: 512,
    height: 512,
    facingMode: 'user',
    deviceId: deviceId
  };

  const capture = useCallback(() => {
    if (webcamRef) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc);
      setOpenWebcamDialog(false);
    }
  }, [webcamRef]);

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) => {
      let devices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
      setDevices(devices);

      if (devices.length > 0 && !localStorage.hasOwnProperty('selectedCamera')) {
        setDeviceId(devices[0].deviceId);
        localStorage.setItem('selectedCamera', devices[0].deviceId);
      }
    },
    [setDevices]
  );

  const setNewDeviceId = (newDeviceId: string) => {
    setDeviceId(newDeviceId);
    localStorage.setItem('selectedCamera', newDeviceId);
  };

  navigator.mediaDevices.enumerateDevices().then((mediaDevices: MediaDeviceInfo[]) => handleDevices(mediaDevices));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:w-auto relative text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full">
                <div className="bg-gray-900 shadow rounded-xl">
                  <div className="p-6">
                    <h3 className="text-lg leading-6 font-bold text-white">What's Your Name?</h3>
                    <div className="text-gray-300">
                      <p>This is how you'll appear to other players.</p>
                    </div>
                    <div className="mt-6">
                      <div className="w-full">
                        <label htmlFor="name" className="sr-only">
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full rounded-xl px-6 py-3 outline-none border"
                          placeholder="Your Name"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-2 my-3">
                        <button className="flex mx-auto hover:bg-gray-800 rounded-xl p-3" onClick={() => setOpenAvatarDialog(true)}>
                          <div className="self-center">
                            <img alt="avatar" src={`/${avatarId}.png`} className="self-center mx-auto h-16 w-16 bg-gray-100 rounded-full p-1 mb-3" />
                            <div className="text-center text-gray-200 text-sm">Select Avatar</div>
                          </div>
                        </button>
                        <button className="flex mx-auto hover:bg-gray-800 rounded-xl p-3" onClick={() => setOpenWebcamDialog(true)}>
                          <div className="self-center">
                            {imageSrc ? (
                              <img alt="avatar" src={imageSrc} className="self-center mx-auto h-16 w-16 bg-gray-100 rounded-full p-1 mb-3" />
                            ) : (
                              <CameraIcon className="self-center mx-auto h-12 w-12 text-gray-200 mb-3" />
                            )}
                            <div className="text-center text-gray-200 text-sm">Use Webcam</div>
                          </div>
                        </button>
                      </div>
                      <button type="submit" onClick={handleOnSave} className="mt-3 w-full bg-red-500 hover:bg-red-600 text-white font-bold rounded-full p-3 ">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <Transition.Root show={openAvatarDialog} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setOpenAvatarDialog}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="w-full sm:w-auto relative text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full">
                        <div className="bg-gray-900 shadow rounded-xl">
                          <div className="grid grid-cols-5 gap-6 p-6">
                            {avatarIds.map((avatarId, index) => (
                              <div
                                className="cursor-pointer flex justify-center"
                                key={`${avatarId}_${index}`}
                                onClick={() => {
                                  setAvatarId(avatarId + '');
                                  setOpenAvatarDialog(false);
                                }}
                              >
                                <img
                                  alt="avatar"
                                  src={`/${avatarId}.png`}
                                  className="self-center bg-gray-100 rounded-full h-20 w-20 border-8 border-clear rounded-full hover:border-gray-700 bg-gray-50"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
            <Transition.Root show={openWebcamDialog} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setOpenWebcamDialog}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="w-full sm:w-auto relative text-left shadow-xl transform transition-all">
                        <div className="bg-gray-900 shadow rounded-xl p-6">
                          <Webcam videoConstraints={videoConstraints} ref={webcamRef} screenshotFormat="image/jpeg" className="rounded-xl" />
                          <div className="flex mt-6">
                            <button type="submit" onClick={capture} className="w-full bg-red-500 hover:bg-red-600 text-white font-bold rounded-full p-3 ">
                              Take Picture
                            </button>
                            <Menu as="div" className="relative inline-block text-left self-center ml-3">
                              <div>
                                <Menu.Button>
                                  <div className="text-gray-200 rounded-full hover:bg-green-700 p-1 cursor-pointer">
                                    <DotsVerticalIcon className="h-6 w-6" />
                                  </div>
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-xl overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div>
                                    {devices.map((device, key) => (
                                      <Menu.Item key={key}>
                                        <button
                                          className="block text-gray-900 text-left hover:bg-gray-200 p-3 font-medium w-full"
                                          onClick={() => {
                                            setNewDeviceId(device.deviceId);
                                          }}
                                        >
                                          <div className="flex gap-3">
                                            {device.deviceId === deviceId && <CheckIcon className="h-8 w-8" />}
                                            {device.label}
                                          </div>
                                        </button>
                                      </Menu.Item>
                                    ))}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
