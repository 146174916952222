import SkullIcon from 'assets/SkullIcon';

export default function NightWerewolfExplainer() {
  return (
    <div>
      <div className="font-bold text-lg text-gray-100 mb-3">Explainer</div>
      <div className="bg-gray-800 border border-gray-700 p-6 rounded-xl">
        <div className="text-2xl font-medium text-gray-100 mb-3">Arise, Werewolf!</div>
        <div className="text-gray-100 mb-6">Night has fallen and that means it's time to choose your next victim.</div>
        <div className="p-3 rounded-xl bg-gray-700 mb-3 text-center">
          <div className="text-gray-300 font-medium">To make your selection, click on a player in the "Survivors" list.</div>
          <div className="text-gray-300 font-medium mt-1.5">
            A
            <div className="inline-block h-6 w-6 mx-3">
              <SkullIcon />
            </div>
            icon will be added to indicate your selection.
          </div>
        </div>
      </div>
    </div>
  );
}
