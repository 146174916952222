import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { FinalBattleAction, GamePhase, GameState, Player, GameRole, encodedLastJsonMessage } from '../Model';
import { VoteRevealPhase, VotingPhase, DayPhase, NightPhase, OpenPhase } from './phases/';
import { getCurrentPlayer } from '../Util';
import { PlayerSetupDialog } from './shared';
import FinalBattlePhase from './phases/FinalBattlePhase';
import { GameContext } from 'GameContext';
import { Base64 } from 'js-base64';

export default function Game() {
  let { gameId } = useParams();

  const [name, setName] = useState<string | undefined>(localStorage.getItem(`${gameId}`) ?? undefined);
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [gameState, setGameState] = useState<GameState | undefined>(undefined);

  let avatarIds = [];
  for (let i = 3; i < 30; ++i) {
    avatarIds.push(i);
  }

  const socketUrl = `${process.env.REACT_APP_API_BASE_URL}?gameId=${gameId}`;

  let currentPlayer: Player | undefined;

  if (name && gameState && gameState.currentState && gameState.currentState.players) {
    currentPlayer = getCurrentPlayer(name ?? '', gameState.currentState.players);
  }

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log('connection opened');

      if (name && gameId) {
        sendJsonMessage({
          action: 'onMessage',
          message: { task: GamePhase.OPEN, gameId: gameId, name: name, avatarId: localStorage.getItem(`${gameId}_avatar`), hasImageSrc: localStorage.hasOwnProperty(`${gameId}_image_src`) }
        });

        if (localStorage.hasOwnProperty(`${gameId}_image_src`)) {
          postImageSrc(gameId, name, localStorage.getItem(`${gameId}_image_src`)!);
        }
      }
    },
    shouldReconnect: (closeEvent) => true
  });

  useEffect(() => {
    if (!name) {
      setOpenNameDialog(true);
    }
    if (lastJsonMessage && (lastJsonMessage as GameState)) {
      let dataObject: encodedLastJsonMessage = lastJsonMessage as encodedLastJsonMessage;
      let decodedGameState = Base64.decode(dataObject.data);
      let decodedGameStateJSON = JSON.parse(decodedGameState);
      setGameState(decodedGameStateJSON as GameState);
    }
  }, [lastJsonMessage, name, setOpenNameDialog]);

  useEffect(() => {
    if (name && gameState && name !== gameState.gameMaster) {
      let playerExists =
        gameState?.currentState.players.find((player) => {
          return player.name === name;
        }) !== undefined;

      if (!playerExists) {
        localStorage.removeItem(`${gameId}`);
        localStorage.removeItem(`${gameId}_avatar`);
        localStorage.removeItem(`${gameId}_image_src`);
        setName(undefined);
      }
    }
  }, [gameState, name, setName]);

  const handleSaveName = async (name: string, avatarId: string, imageSrc?: string) => {
    setOpenNameDialog(false);
    if (gameId) {
      localStorage.setItem(`${gameId}`, `${name}`);
      localStorage.setItem(`${gameId}_avatar`, `${avatarId}`);

      if (imageSrc) {
        localStorage.setItem(`${gameId}_image_src`, `${imageSrc}`);
      }

      setName(name);

      if (gameState?.currentState.gamePhase === GamePhase.OPEN) {
        sendJsonMessage({
          action: 'onMessage',
          message: {
            task: GamePhase.OPEN,
            gameId: gameId,
            name: localStorage.getItem(gameId),
            avatarId: localStorage.getItem(`${gameId}_avatar`),
            hasImageSrc: imageSrc !== undefined
          }
        });
      } else {
        sendJsonMessage({
          action: 'onMessage',
          message: {
            task: 'REJOIN_GAME',
            gameId: gameId,
            name: localStorage.getItem(gameId),
            avatarId: localStorage.getItem(`${gameId}_avatar`),
            hasImageSrc: imageSrc !== undefined
          }
        });
      }

      if (localStorage.hasOwnProperty(`${gameId}_image_src`)) {
        postImageSrc(gameId, name, localStorage.getItem(`${gameId}_image_src`)!);
      }
    }
  };

  const postImageSrc = async (gameId: string, name: string, imageSrc: string) => {
    await fetch('https://f5mquh7rxi.execute-api.us-east-1.amazonaws.com/test', {
      method: 'POST',
      body: JSON.stringify({
        gameId: gameId,
        name: name,
        imageSrc: imageSrc
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleStartGame = async () => {
    sendJsonMessage({ action: 'onMessage', message: { task: GamePhase.ASSIGN_ROLES, gameId: gameId } });
  };

  const handleSelectVictim = async (victim: string) => {
    sendJsonMessage({ action: 'onMessage', message: { task: GamePhase.NIGHT, gameId: gameId, name: name, victim: victim } });
  };

  const handleSelectSeerGuess = async (name: string) => {
    sendJsonMessage({ action: 'onMessage', message: { task: GamePhase.NIGHT, gameId: gameId, guess: name } });
  };

  const handleSelectPatient = async (name: string) => {
    sendJsonMessage({ action: 'onMessage', message: { task: GamePhase.NIGHT, gameId: gameId, patient: name } });
  };

  const handleSubmitVote = async (guessName: string) => {
    sendJsonMessage({ action: 'onMessage', message: { task: 'VOTE', gameId: gameId, name: name, guessName: guessName } });
  };

  const handleEndVoting = async () => {
    sendJsonMessage({ action: 'onMessage', message: { task: GamePhase.VOTE_REVEAL, gameId: gameId } });
  };

  const handleMoveToNextPhase = async (gamePhase: GamePhase) => {
    sendJsonMessage({ action: 'onMessage', message: { task: gamePhase, gameId: gameId } });
  };

  const handleGenerateDummyPlayers = async () => {
    sendJsonMessage({ action: 'onMessage', message: { task: 'GENERATE_DUMMY_PLAYERS', gameId: gameId } });
  };

  const handleFinalBattleAction = async (finalBattleAction: FinalBattleAction, isWerewolf: boolean) => {
    sendJsonMessage({ action: 'onMessage', message: { task: 'FINAL_BATTLE_ACTION', gameId: gameId, finalBattleAction: finalBattleAction, isWerewolf: isWerewolf } });
  };

  const handleSendWolfChatMessage = async (message: string) => {
    sendJsonMessage({ action: 'onMessage', message: { task: 'SEND_WOLF_CHAT_MESSAGE', gameId: gameId, name: currentPlayer?.name, message: message } });
  };

  const handleRemovePlayer = async (name: string) => {
    sendJsonMessage({ action: 'onMessage', message: { task: 'REMOVE_PLAYER', gameId: gameId, name: name } });
  };

  const handleChangeRole = async (name: string, role: GameRole) => {
    sendJsonMessage({ action: 'onMessage', message: { task: 'ASSIGN_ROLE', gameId: gameId, name: name, role: role } });
  };

  const handleGhostSelection = async (name: string) => {
    sendJsonMessage({ action: 'onMessage', message: { task: 'GHOST_SELECT', gameId: gameId, name: name } });
  };

  let phaseUI = <div />;
  if (gameState && name && gameState.currentState && gameState.currentState.gamePhase) {
    switch (gameState.currentState.gamePhase) {
      case GamePhase.OPEN:
        phaseUI = <OpenPhase handleStartGame={handleStartGame} handleGenerateDummyPlayers={handleGenerateDummyPlayers} />;
        break;
      case GamePhase.NIGHT:
        phaseUI = (
          <NightPhase
            handleSelectVictim={handleSelectVictim}
            handleSelectSeerGuess={handleSelectSeerGuess}
            handleSelectPatient={handleSelectPatient}
            handleNextPhase={handleMoveToNextPhase}
            handleSendWolfChatMessage={handleSendWolfChatMessage}
            handleGhostSelection={handleGhostSelection}
          />
        );
        break;
      case GamePhase.DAY:
        phaseUI = <DayPhase handleNextPhase={handleMoveToNextPhase} />;
        break;
      case GamePhase.VOTING:
        phaseUI = <VotingPhase handleSubmitVote={handleSubmitVote} handleEndVoting={handleEndVoting} />;
        break;
      case GamePhase.VOTE_REVEAL:
        phaseUI = <VoteRevealPhase handleNextPhase={handleMoveToNextPhase} />;
        break;
      case GamePhase.FINAL_BATTLE:
        phaseUI = <FinalBattlePhase handleFinalBattleAction={handleFinalBattleAction} />;
        break;
      case GamePhase.WEREWOLF_WINS:
        phaseUI = <FinalBattlePhase handleFinalBattleAction={handleFinalBattleAction} werewolvesWin={true} />;
        break;
      case GamePhase.VILLAGERS_WIN:
        phaseUI = <FinalBattlePhase handleFinalBattleAction={handleFinalBattleAction} villagersWin={true} />;
        break;
      default:
        phaseUI = <div>{gameState.currentState.gamePhase}</div>;
    }
  }

  return (
    <GameContext.Provider value={{ gameState: gameState, name: name, handleRemovePlayer: handleRemovePlayer, handleChangeRole: handleChangeRole }}>
      <div className="bg-gray-800 noiseBackground">
        <div className="sm:p-6">{phaseUI}</div>
        {gameId && <PlayerSetupDialog open={openNameDialog} setOpen={setOpenNameDialog} onSave={handleSaveName} />}
      </div>
    </GameContext.Provider>
  );
}
