import { GameState, WolfChatEntry } from 'Model';
import { getPlayerByName } from 'Util';
import PlayerListItem, { PlayerListItemSize } from './PlayerListItem';
import dateFormat from 'dateformat';

export type WolfChatItemProps = {
  gameState: GameState;
  entry: WolfChatEntry;
};

export default function WolfChatItem(props: WolfChatItemProps) {
  const { entry, gameState } = props;

  let entryDate = dateFormat(entry.timestamp);
  let player = getPlayerByName(gameState, entry.playerName)!;
  return (
    <li key={entry.timestamp + entry.playerName} className="bg-gray-800 px-6 pt-3 pb-6 border-b border-gray-700">
      <div className="text-gray-300 mb-3 text-lg">{entry.message}</div>
      <div className="flex mt-3">
        <div className={`self-center rounded-full overflow-hidden mr-3 border-2 border-gray-400 bg-white ` + (player.isAlive ? 'opacity-100' : 'opacity-20')}>
          <img alt="avatar" src={`/${player.avatarId}.png`} className="h-6 w-6 p-1" />
        </div>
        <div className="self-center text-left mr-3">
          <div className={'font-bold ' + (player.isAlive ? 'text-gray-300' : 'line-through text-gray-500') + ' text-sm'}>{player.name}</div>
          {entryDate && <div className="font-medium text-xs text-gray-400">{entryDate}</div>}
        </div>
      </div>
    </li>
  );
}
