export default function SkullIcon() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" fill="currentColor" stroke="currentColor">
        <g>
          <circle fill="none" cx="28.988" cy="56.24" r="9.216"></circle>
          <polygon fill="none" points="56.266,73.334 50.001,62.482 43.733,73.334 43.735,73.334  "></polygon>
          <circle fill="none" cx="71.013" cy="56.24" r="9.215"></circle>
          <path d="M55.482,5H44.517C26.073,5,11.12,19.952,11.12,38.397V62.24c0,10.218,7.478,18.669,17.255,20.237v8.992   c0,1.951,1.581,3.531,3.531,3.531s3.53-1.581,3.53-3.531v-2.378c0-0.55,0.445-0.995,0.994-0.995c0.548,0,0.991,0.445,0.991,0.995   v2.378c0,1.951,1.581,3.531,3.532,3.531c1.949,0,3.53-1.581,3.53-3.531v-2.378c0-0.55,0.445-0.995,0.994-0.995   c0.547,0,0.992,0.445,0.992,0.995v2.378c0,1.951,1.581,3.531,3.531,3.531c1.948,0,3.529-1.581,3.529-3.531v-2.378h0.002   c0-0.55,0.443-0.995,0.993-0.995c0.548,0,0.993,0.445,0.993,0.995v2.378c0,1.951,1.581,3.531,3.53,3.531   c1.949,0,3.53-1.581,3.53-3.531v-2.378c0-0.55,0.445-0.995,0.993-0.995c0.549,0,0.993,0.445,0.993,0.995v2.378   c0,1.951,1.581,3.531,3.531,3.531c1.948,0,3.531-1.581,3.531-3.531v-8.992C81.403,80.909,88.88,72.458,88.88,62.24V38.397   C88.88,19.952,73.927,5,55.482,5z M50.001,62.482l6.265,10.851H43.735h-0.002L50.001,62.482z M19.773,56.239   c0-5.089,4.127-9.214,9.214-9.214c5.091,0,9.218,4.126,9.218,9.214c0,5.091-4.127,9.217-9.218,9.217   C23.9,65.457,19.773,61.33,19.773,56.239z M71.012,65.457c-5.088,0-9.214-4.126-9.214-9.217c0-5.089,4.127-9.214,9.214-9.214   c5.09,0,9.216,4.126,9.216,9.214C80.228,61.33,76.103,65.457,71.012,65.457z"></path>
        </g>
      </svg>
    </div>
  );
}
