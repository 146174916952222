import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { GameContext } from 'GameContext';
import { GameRole, Player } from 'Model';
import { Fragment, useContext } from 'react';
import { PlayerImage } from './PlayerImage';

export enum PlayerListItemSize {
  SMALL,
  MEDIUM
}

export type PlayerListItemProps = {
  gameId: string;
  player: Player;
  description?: string;
  icon?: React.ReactElement;
  onClick?: Function;
  cursorClass?: string;
  bgOuterColorClass?: string;
  bgInteriorColorClass?: string;
  borderColorClass?: string;
  size?: PlayerListItemSize;
  showGameMasterMenu?: boolean;
};

export default function PlayerListItem(props: PlayerListItemProps) {
  const {
    gameId,
    player,
    description,
    icon,
    onClick = () => {},
    cursorClass = '',
    bgOuterColorClass = 'bg-gray-700',
    bgInteriorColorClass = 'bg-gray-800',
    borderColorClass = 'border-gray-600',
    size = PlayerListItemSize.MEDIUM,
    showGameMasterMenu = false
  } = props;

  const { handleRemovePlayer, handleChangeRole } = useContext(GameContext);

  const changeRole = (name: string, role: GameRole) => {
    if (handleChangeRole) {
      handleChangeRole(name, role);
    }
  };

  return (
    <div className={`flex justify-between rounded-xl ${cursorClass} ${bgOuterColorClass}`} onClick={() => onClick()}>
      <div className={`p-3 rounded-xl w-full border ${borderColorClass} ${bgInteriorColorClass}`}>
        <div className="flex">
          <div className={`self-center rounded-full overflow-hidden mr-3 border-4 border-gray-400 bg-white ${borderColorClass} ` + (player.isAlive ? 'opacity-100' : 'opacity-20')}>
            <PlayerImage name={player.name} hasImageSrc={player.hasImageSrc} avatarId={player.avatarId} classes={size === PlayerListItemSize.MEDIUM ? 'h-12 w-12' : 'h-8 w-8 p-1'} />
          </div>
          <div className="self-center text-left mr-3">
            <div className={'font-bold ' + (player.isAlive ? 'text-gray-300' : 'line-through text-gray-500') + ' ' + (size === PlayerListItemSize.MEDIUM ? '' : 'text-sm')}>{player.name}</div>
            {description && <div className={size === PlayerListItemSize.MEDIUM ? 'font-medium text-gray-100' : 'font-medium text-sm text-gray-400'}>{description}</div>}
          </div>
          <div className="grow self-center grid justify-items-end">{icon}</div>
          {showGameMasterMenu && (
            <div>
              <Menu as="div" className="relative inline-block text-left ml-3">
                <div>
                  <Menu.Button>
                    <div className="text-gray-200 rounded-full hover:bg-green-700 p-1 cursor-pointer">
                      <DotsVerticalIcon className="h-6 w-6" />
                    </div>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-xl overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div>
                      <Menu.Item>
                        <button
                          className="block text-gray-900 text-left hover:bg-gray-200 p-3 font-medium w-full"
                          onClick={() => {
                            if (handleRemovePlayer) {
                              handleRemovePlayer(player.name);
                            }
                          }}
                        >
                          Remove Player
                        </button>
                      </Menu.Item>
                      <div className="p-3 bg-gray-100 font-bold ">Change Role</div>
                      <Menu.Item>
                        <button
                          className="block text-gray-900 text-left hover:bg-gray-200 p-3 font-medium w-full"
                          onClick={() => {
                            changeRole(player.name, GameRole.Werewolf);
                          }}
                        >
                          Werewolf
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          className="block text-gray-900 text-left hover:bg-gray-200 p-3 font-medium w-full"
                          onClick={() => {
                            changeRole(player.name, GameRole.Seer);
                          }}
                        >
                          Seer
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          className="block text-gray-900 text-left hover:bg-gray-200 p-3 font-medium w-full"
                          onClick={() => {
                            changeRole(player.name, GameRole.Witch);
                          }}
                        >
                          Witch
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          className="block text-gray-900 text-left hover:bg-gray-200 p-3 font-medium w-full"
                          onClick={() => {
                            changeRole(player.name, GameRole.Villager);
                          }}
                        >
                          Villager
                        </button>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
