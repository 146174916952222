export default function WolfIcon() {
  return (
    <div className="h-20 w-20 self-center text-red-500">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" fill="currentColor" stroke="currentColor">
        <g>
          <path d="M75.888,42.593l-1.492-0.533c-0.53-2.236-2.234-2.45-2.234-2.45c0.853,1.384,0,2.77,0,2.77s-0.141,0.024-0.382,0.07   c-0.396-0.479-1.291-1.438-2.354-1.701c0,0,0.643,1.281,0.254,2.092c-1.246,0.232-2.828,0.523-4.438,0.817   c-3.514,0.638-2.022-2.094-1.706-4.153c0.115-0.722,0.703-1.874,1.431-3.08c0.744,0.256,1.374,1.095,1.374,1.095   c0.212-0.951-0.128-2.028-0.386-2.655c0.249-0.371,0.489-0.727,0.727-1.065c0.947-0.122,1.907,1.145,1.907,1.145   c0.266-1.198-0.34-2.597-0.552-3.035c0.178-0.24,0.293-0.389,0.293-0.389c1.17-0.213,2.662,1.063,2.662,1.063   c0.532-1.704-1.92-4.578-1.92-4.578c0.321-1.171,0.533-3.302,0-4.152c-0.53-0.853-0.424-1.491-0.742-2.555   c-0.319-1.065-1.278-0.639-1.278-0.639c-1.489-0.959-5.219,3.727-5.219,3.727l-9.579,7.345c-2.663,0.108-0.64-0.744-4.582-0.424   c-3.938,0.319-8.942,6.919-8.942,6.919l-5.217,2.129c-9.369,2.982-12.988-0.106-9.688,3.515c3.3,3.618,7.666,5.342,7.666,5.342   l-0.532,3.176c-1.917,2.234-7.561,1.915-7.561,1.915c1.917,3.939,3.727,4.473,3.727,4.473l-4.471,1.385   c8.198,27.256,38.011,18.951,38.011,18.951c-3.408-0.959-4.258-5.004-4.258-5.004c1.915,1.063,5.109-1.81,5.109-1.81   c-3.516-0.426-5.856-8.946-5.856-8.946c3.234-1.247,5.005-5.216,5.005-5.216s3.429-3.459,6.596-6.177   c0.007-0.008,0.013-0.01,0.021-0.018c2.656-2.378,5.839-0.514,5.839-0.514c1.387-1.169,2.77-4.79,2.77-4.79   C76.739,44.935,75.888,42.593,75.888,42.593z M46.69,40.454c0.5-4.38,5.131-4.129,5.131-4.129   C52.697,41.58,46.69,40.454,46.69,40.454z"></path>
        </g>
      </svg>
    </div>
  );
}
