import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PlayerSetupDialog } from './shared';
const { v4: uuidv4 } = require('uuid');

export default function Home() {
  let navigate = useNavigate();
  const [openNameDialog, setOpenNameDialog] = useState(false);

  function createNewGame(name: string, avatarId: string, imageSrc: string) {
    let gameId = uuidv4();
    localStorage.setItem(`${gameId}`, `${name}`);
    localStorage.setItem(`${gameId}_avatar`, `${avatarId}`);
    if (imageSrc) {
      localStorage.setItem(`${gameId}_image_src`, `${imageSrc}`);
    }
    navigate(`/${gameId}`);
  }

  let avatarIds = [];
  for (let i = 3; i < 30; ++i) {
    avatarIds.push(i);
  }

  return (
    <div className="p-3 sm:p-6">
      <div>
        <div className={'text-center rounded-xl bg-contain bg-no-repeat bg-top mx-auto p-6 w-full lg:w-3/4 lg:max-w-7xl h-auto min-container-height bg-gray-900 nightBackground'}>
          <div className="text-center pt-6 mb-12">
            <div className="text-7xl font-bold text-white">The werewolf is among us.</div>
          </div>
          <div>
            <Button buttonText="Create New Game" onClick={() => setOpenNameDialog(true)} />
          </div>
        </div>
        <div className="rounded-xl bg-contain bg-no-repeat bg-top mx-auto p-6 w-full lg:w-3/4 lg:max-w-7xl mt-3 sm:mt-6 h-auto min-container-height bg-gray-900">
          <div className="font-bold text-4xl text-yellow-500 mb-6">How To Play:</div>
          <div className="text-gray-300 mb-12 text-lg">
            WhoWolfYouWolf is based on the party game{' '}
            <a className="hover:text-blue-400 text-blue-500" href="https://en.wikipedia.org/wiki/Mafia_(party_game)" target="_blank" rel="noreferrer">
              Werewolf (sometimes called Mafia).
            </a>
          </div>
          <div className="font-bold text-2xl text-yellow-500 mb-6">Player Roles:</div>
          <div className="mb-12">
            <ul>
              <li className="text-gray-300 mb-6">
                <div className="font-bold">GameMaster:</div> The GameMaster is the person who created the game and is responsible for moving the game between phases.
              </li>
              <li className="text-gray-300 mb-6">
                <div className="font-bold">Werewolf:</div> The werewolf is the antagonist of the game. Each night the werewolf can choose a victim to remove from the game. The werewolf does not want
                to be found out by the villagers.
              </li>
              <li className="text-gray-300 mb-6">
                <div className="font-bold">Seer:</div> The seer has the power to guess who the werewolf is once each night. Villagers are suspicious of the seer's power, so it's important to keep your
                identity a secret.
              </li>
              <li className="text-gray-300 mb-6">
                <div className="font-bold">Witch:</div> The Witch has the ability to protect 1 person each night. If that person is the werewolf's victim, then they won't be removed from the game. The
                villagers are afraid of science, so keep your identity a secret.
              </li>
              <li className="text-gray-300">
                <div className="font-bold">Villager:</div>Most players will be villagers. Each day the villagers will gather to discuss and vote on who they think the werewolf is.
              </li>
            </ul>
          </div>
          <div className="font-bold text-2xl text-yellow-500 mb-6">Sequence Of Play:</div>
          <ul className="mb-12">
            <li className="text-gray-300 mb-6">
              <div className="font-bold">Night Fall:</div> The werewolf, witch, and seer complete their actions. Villagers hope they don't die.
            </li>
            <li className="text-gray-300 mb-6">
              <div className="font-bold">Day Break:</div> The werewolf's victim is revealed (if not protected by the witch). All villagers will discuss who they think the werewolf could be.
            </li>
            <li className="text-gray-300 mb-6">
              <div className="font-bold">Voting:</div> All villagers vote on who they think the werewolf is.
            </li>
            <li className="text-gray-300 mb-6">
              <div className="font-bold">Vote Results:</div> If the villager with the most votes is the werewolf, then the villagers win. Otherwise, the villager with the most votes is exiled from the
              village.
            </li>
            <li className="text-gray-300 mb-6">
              <div className="font-bold">Repeat Until Game Is Won (see below)</div>
            </li>
          </ul>
          <div className="font-bold text-2xl text-yellow-500 mb-6">How To Win:</div>
          <ul>
            <li className="text-gray-300 mb-6">
              <div className="font-bold">Villagers Win:</div> The villagers win when they correctly vote on who the werewolf is.
            </li>
            <li className="text-gray-300 mb-6">
              <div className="font-bold">Werewolf Wins:</div> When there is only 1 other villager left.
            </li>
          </ul>
        </div>
      </div>
      <PlayerSetupDialog open={openNameDialog} setOpen={setOpenNameDialog} onSave={createNewGame} />
    </div>
  );
}
