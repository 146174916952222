import { GameState } from 'Model';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { StringMappingType } from 'typescript';
import { GameContext } from 'GameContext';

export type PlayerImageProps = {
  name: string;
  hasImageSrc: boolean;
  avatarId: string;
  classes: string;
};
export function PlayerImage(props: PlayerImageProps) {
  const { gameState } = useContext(GameContext);
  const { name, hasImageSrc, avatarId, classes } = props;
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  useEffect(() => {
    if (hasImageSrc) {
      axios
        .get(`https://f5mquh7rxi.execute-api.us-east-1.amazonaws.com/test?gameId=${gameState?.gameId}&name=${name}`)
        .then(function (response) {
          if (response.data) {
            setImageSrc(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {});
    }
  }, []);

  let imageContent = imageSrc ?? `/${avatarId}.png`;

  return <img alt="avatar" src={imageContent} className={classes} />;
}
