export type GameContextObject = {
  gameState?: GameState;
  name?: string;
  handleRemovePlayer?: Function;
  handleChangeRole?: Function;
};

export type encodedLastJsonMessage = {
  data: string;
};

export type GameState = {
  gameId: string;
  gameMaster: string;
  gameMasterAvatarId: string;
  gameMasterHasImageSrc: boolean;
  currentState: {
    gamePhase: GamePhase;
    players: Player[];
    gameRounds: GameRound[];
    finalBattle?: FinalBattle;
    wolfChat: WolfChatEntry[];
  };
};

export type FinalBattle = {
  werewolfScore: number;
  finalVillagerScore: number;
  finalBattleRounds: FinalBattleRound[];
  werewolfModifier: number;
  villagerModifier: number;
};

export type WolfChatEntry = {
  timestamp: number;
  playerName: string;
  message: string;
};

export enum GamePhase {
  JOIN = 'JOIN',
  OPEN = 'OPEN',
  ASSIGN_ROLES = 'ASSIGN_ROLES',
  NIGHT = 'NIGHT',
  DAY = 'DAY',
  VOTING = 'VOTING',
  VOTE_REVEAL = 'VOTE_REVEAL',
  NEXT_ROUND = 'NEXT_ROUND',
  WEREWOLF_WINS = 'WEREWOLF_WINS',
  VILLAGERS_WIN = 'VILLAGERS_WIN',
  FINAL_BATTLE = 'FINAL_BATTLE'
}

export enum GameRole {
  None = 'None',
  GameMaster = 'GameMaster',
  Werewolf = 'Werewolf',
  Seer = 'Seer',
  Witch = 'Witch',
  Villager = 'Villager'
}

export type Player = {
  name: string;
  role: GameRole;
  roleDescription: string;
  isAlive: boolean;
  avatarId: string;
  isChampion: boolean;
  hasImageSrc: boolean;
};

export type GameRound = {
  timestamp: number;
  werewolfVictim: string | undefined;
  packVictimChoices: PackVictimChoice[];
  seerGuess: string | undefined;
  witchGuarded: string | undefined;
  villagerGuess: string | undefined;
  votes: PlayerVote[];
  voteResults: string;
  isTie: boolean;
  ghostVotes: any;
};

export type PlayerVote = {
  name: string;
  guessName: string;
};

export type Tally = {
  guessName: string;
  totalVotes: number;
};

export type PackVictimChoice = {
  name: string;
  choice: string;
};

export type FinalBattleRound = {
  timestamp: number;
  werewolfAction: FinalBattleAction;
  villagerAction: FinalBattleAction;
  outputNarrative: string;
  werewolfRoll: number;
  villagerRoll: number;
  werewolfRunningScore: number;
  villagerRunningScore: number;
};

export enum FinalBattleAction {
  HIDE = 'HIDE', // LURK for Werewolf
  ATTACK = 'ATTACK',
  DODGE = 'DODGE'
}
