import { GameState } from 'Model';
import { getPlayerByName } from 'Util';
import { PlayerList, PlayerCard, GameUIContainer } from 'components/shared';

export type DeadStateProps = {
  gameState: GameState;
  name: string;
};

export default function DeadState(props: DeadStateProps) {
  const { gameState, name } = props;
  let currentPlayer = getPlayerByName(gameState, name);

  return (
    <GameUIContainer
      headerText="Bummer, you're dead!"
      subHeaderText=""
      headerButton={undefined}
      isNight={true}
      leftColumn={
        currentPlayer && (
          <PlayerCard
            player={currentPlayer}
            primaryText={currentPlayer.name}
            secondaryText={currentPlayer.role}
            avatarId={currentPlayer.avatarId}
            descriptionText={`<div class="text-center">${currentPlayer.roleDescription}</div>`}
            showLabel={true}
          />
        )
      }
      rightColumn={<PlayerList gameState={props.gameState} onPlayerSelect={() => {}} filterCurrentPlayer={true} name={props.name} />}
    />
  );
}
