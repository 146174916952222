import { Dialog, Transition } from '@headlessui/react';
import { GameContext } from 'GameContext';
import { Fragment, useContext } from 'react';
import GameHeader from './GameHeader';
import { GameMasterCard } from './GameMasterCard';
import GameSubHeader from './GameSubHeader';

export type GameUIContainerProps = {
  headerText: string;
  subHeaderText: string;
  headerButton?: React.ReactNode | undefined;
  leftColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
  centerColumn?: React.ReactNode;
  isNight: boolean;
  openCardOverlay?: boolean;
  onCloseCardOverlay?: Function;
  overlayContent?: React.ReactNode;
  bannerMessage?: string;
};

export default function GameUIContainer(props: GameUIContainerProps) {
  const { gameState } = useContext(GameContext);

  if (gameState) {
    const { headerText, subHeaderText, headerButton, leftColumn, rightColumn, centerColumn, isNight, openCardOverlay = false, onCloseCardOverlay = () => {}, overlayContent, bannerMessage } = props;
    return (
      <div>
        <Transition.Root show={openCardOverlay} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              onCloseCardOverlay();
            }}
          >
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="w-full sm:w-auto relative text-left overflow-hidden shadow-xl transform transition-all">{overlayContent}</Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div>
          <div className="px-6 sm:px-0 sm:flex lg:grid lg:grid-cols-12 gap-12 w-full 2xl:w-3/4 2xl:max-w-7xl sm:mx-auto">
            <GameMasterCard gameState={gameState} />
            <div className="col-span-6 mx-auto">
              <div className="self-center mb-6 sm:mb-0 text-center sm:text-left lg:text-center">
                <div className="mb-3">
                  <GameHeader text={headerText} />
                </div>
                <GameSubHeader text={subHeaderText} />
              </div>
            </div>
            <div className="self-center mt-6 lg:mt-0 col-span-3">{headerButton}</div>
          </div>
          <div
            className={
              'sm:rounded-xl bg-contain bg-no-repeat bg-top mx-auto p-6 w-full 2xl:w-3/4 2xl:max-w-7xl mt-6 min-container-height ' +
              (isNight ? 'bg-gray-900 nightBackground' : 'bg-gray-700 dayBackground')
            }
          >
            {bannerMessage && (
              <div className="bg-gray-800 text-center rounded-xl p-3 mb-6">
                <div className="rounded-xl border border-gray-700 p-6 text-gray-50 text-xl font-medium">{bannerMessage}</div>
              </div>
            )}
            <div>
              <div className="hidden lg:flex lg:justify-between gap-6">
                <div className="mb-6 sm:mb-0 w-full sm:w-80">{leftColumn}</div>
                <div className="w-full">{centerColumn}</div>
                <div>{rightColumn}</div>
              </div>
              <div className="hidden md:grid lg:hidden grid-cols-12 gap-6">
                <div className="col-span-5">
                  <div className="mb-6">{rightColumn}</div>
                  <div>{leftColumn}</div>
                </div>
                <div className="col-span-7">{centerColumn && centerColumn}</div>
              </div>
              <div className="grid md:hidden grid-cols-1 gap-6">
                <div>
                  {centerColumn && <div className="mb-6">{centerColumn}</div>}
                  {rightColumn}
                </div>
                <div>{leftColumn}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}
