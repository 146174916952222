import { GameState } from 'Model';
import { useState } from 'react';
import { getPlayerByName } from 'Util';
import { PlayerListItem } from '.';
import { Button } from '.';
import { PlayerListItemSize } from './PlayerListItem';
import WolfChatItem from './WolfChatItem';

export type WolfChatProps = {
  gameState: GameState;
  onSave: Function;
};

export default function WolfChat(props: WolfChatProps) {
  const { gameState, onSave } = props;
  const [message, setMessage] = useState<string | undefined>();
  return (
    <div>
      <div className="font-bold text-lg text-gray-100 mb-3">Pack Chat</div>
      <div className="bg-gray-800 border border-gray-700 rounded-xl overflow-hidden">
        <div className="grid grid-cols-12 p-6">
          <input
            type="text"
            name="message"
            id="message"
            className="col-span-10 w-full rounded-l-xl px-6 py-3 outline-none border"
            placeholder="Type your message"
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
          />
          <div className="col-span-2 h-full">
            <button
              className="bg-red-500 hover:bg-red-600 text-white rounded-r-xl font-bold p-3 w-full h-full"
              onClick={() => {
                onSave(message);
                setMessage('');
              }}
            >
              Send
            </button>
          </div>
        </div>
        {gameState.currentState.wolfChat.length === 0 ? (
          <div className="px-6 pb-6 text-lg font-bold text-gray-200 text-center">Send a message to start chating with your pack!</div>
        ) : (
          <ul className="overflow-hidden hover:overflow-y-scroll border-t border-gray-700" style={{ maxHeight: '400px' }}>
            {gameState.currentState.wolfChat.map((wolfChatEntry, index) => (
              <WolfChatItem gameState={gameState} entry={wolfChatEntry} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
