import { CheckCircleIcon } from '@heroicons/react/solid';
import { GameUIContainer, PlayerCard } from 'components/shared';
import { GameContext } from 'GameContext';
import { FinalBattleAction } from 'Model';
import { useContext } from 'react';
import { getCurrentFinalBattleRound, getFinalVillager, getPlayerByName, getWerewolf } from 'Util';

export type FinalBattlePhaseProps = {
  handleFinalBattleAction: Function;
  villagersWin?: boolean;
  werewolvesWin?: boolean;
};

export default function FinalBattlePhase(props: FinalBattlePhaseProps) {
  const { gameState, name } = useContext(GameContext);

  if (gameState && name) {
    const { handleFinalBattleAction, villagersWin = false, werewolvesWin = false } = props;

    let werewolf = getWerewolf(gameState);
    let finalVillager = getFinalVillager(gameState);
    let currentPlayer = getPlayerByName(gameState, name);

    let isWerewolf = werewolf?.name === currentPlayer?.name;
    let isFinalVillager = finalVillager?.name === currentPlayer?.name;

    let currentFinalBattleRound = getCurrentFinalBattleRound(gameState);

    let werewolfModifier = gameState.currentState.finalBattle?.werewolfModifier ?? 0;
    let villagerModifier = gameState.currentState.finalBattle?.villagerModifier ?? 0;

    let headerText = 'Final Battle!';
    let subHeaderText = 'First to 10 Points Wins!';
    let overlayContent = undefined;
    if (werewolvesWin) {
      headerText = 'Werewolf Wins!';
      subHeaderText = 'The townpeople were no match for this evil.';
      overlayContent = <div>Werewolves Win</div>;
    } else if (villagersWin) {
      headerText = 'The Villagers Win!';
      subHeaderText = 'Evil was no match for your bravery.';
      overlayContent = <div>Villagers Win</div>;
    }

    return (
      <GameUIContainer
        headerText={headerText}
        subHeaderText={subHeaderText}
        headerButton={undefined}
        isNight={true}
        overlayContent={overlayContent}
        centerColumn={
          <div>
            <div className="w-full">
              {(isWerewolf || isFinalVillager) && currentFinalBattleRound && !werewolvesWin && !villagersWin && (
                <div className="mb-6">
                  <div className="grid grid-cols-3 gap-0 w-full rounded-xl overflow-hidden">
                    <button
                      className="bg-red-500 hover:bg-red-600 p-3 font-bold"
                      onClick={() => {
                        handleFinalBattleAction(FinalBattleAction.ATTACK, isWerewolf);
                      }}
                    >
                      <div className="flex justify-center gap-1.5">
                        <span className="self-center">Attack</span>
                        {isWerewolf && currentFinalBattleRound.werewolfAction === FinalBattleAction.ATTACK && <CheckCircleIcon className="h-6 w-6 self-center" />}
                        {isFinalVillager && currentFinalBattleRound.villagerAction === FinalBattleAction.ATTACK && <CheckCircleIcon className="h-6 w-6 self-center" />}
                      </div>
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 p-3 font-bold border-l border-red-700 border-r"
                      onClick={() => {
                        handleFinalBattleAction(FinalBattleAction.DODGE, isWerewolf);
                      }}
                    >
                      <div className="flex justify-center gap-1.5">
                        <span className="self-center">Dodge</span>
                        {isWerewolf && currentFinalBattleRound.werewolfAction === FinalBattleAction.DODGE && <CheckCircleIcon className="h-6 w-6 self-center" />}
                        {isFinalVillager && currentFinalBattleRound.villagerAction === FinalBattleAction.DODGE && <CheckCircleIcon className="h-6 w-6 self-center" />}
                      </div>
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 p-3 font-bold"
                      onClick={() => {
                        handleFinalBattleAction(FinalBattleAction.HIDE, isWerewolf);
                      }}
                    >
                      <div className="flex justify-center gap-1.5">
                        {isWerewolf ? <span className="self-center">Lurk</span> : <span className="self-center">Hide</span>}
                        {isWerewolf && currentFinalBattleRound.werewolfAction === FinalBattleAction.HIDE && <CheckCircleIcon className="h-6 w-6 self-center" />}
                        {isFinalVillager && currentFinalBattleRound.villagerAction === FinalBattleAction.HIDE && <CheckCircleIcon className="h-6 w-6 self-center" />}
                      </div>
                    </button>
                  </div>
                  <div className="text-center text-gray-400 font-medium">Click your action</div>
                </div>
              )}
              {villagersWin && (
                <div className="p-6 text-5xl text-center">
                  <div className="pb-6">🎉 🎉 🎉</div>
                  <div className="font-bold text-green-500 pb-6">Villagers Win!</div>
                  <div className="pb-12">🎉 🎉 🎉</div>
                </div>
              )}
              {werewolvesWin && (
                <div className="p-6 text-5xl text-center">
                  <div className="pb-6">☠️ ☠️ ☠️</div>
                  <div className="font-bold text-red-500 pb-6">Werewolves Win!</div>
                  <div className="pb-12">☠️ ☠️ ☠️</div>
                </div>
              )}
            </div>
            <div>
              {gameState.currentState.finalBattle &&
                gameState.currentState.finalBattle.finalBattleRounds.map(
                  (finalBattleRound, index) =>
                    finalBattleRound.outputNarrative && (
                      <div className="rounded-xl border border-gray-700 mb-6 bg-gray-800  ">
                        <div className="rounded-xl border border-gray-700 bg-gray-800 overflow-hidden">
                          <div className="grid grid-cols-2 border-b border-gray-700 text-lg ">
                            <div className="flex justify-between text-gray-50 py-3 px-6 border-r border-gray-700">
                              <div>
                                <div className="">Villager</div>
                                <div className="font-medium">{finalBattleRound.villagerAction}</div>
                              </div>
                              <div>
                                <div className="">Roll</div>
                                <div className="font-medium">{finalBattleRound.villagerRoll}</div>
                              </div>
                            </div>
                            <div className="flex justify-between text-gray-50 py-3 px-6">
                              <div>
                                <div className="">Werewolf</div>
                                <div className="font-medium">{finalBattleRound.werewolfAction}</div>
                              </div>
                              <div>
                                <div className="">Roll</div>
                                <div className="font-medium">{finalBattleRound.werewolfRoll}</div>
                              </div>
                            </div>
                          </div>
                          <div className="py-3 px-6 text-gray-50 text-lg">{finalBattleRound.outputNarrative}</div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        }
        leftColumn={
          <PlayerCard
            player={finalVillager}
            primaryText="Final Villager!"
            secondaryText={`${finalVillager?.name} Points:`}
            avatarId={finalVillager?.avatarId ?? ''}
            showLabel={false}
            descriptionText={`
          <div>
            <div class="text-7xl text-center font-bold text-gray-100 mb-3">${gameState.currentState.finalBattle?.finalVillagerScore}</div>
            <div class="text-center font-bold ${villagerModifier >= 0 ? 'text-green-500' : 'text-red-500'}">
              Roll Modifier: ${villagerModifier}
            </div>
          </div>`}
          />
        }
        rightColumn={
          <PlayerCard
            player={werewolf}
            primaryText="Werewolf!"
            secondaryText={`${werewolf?.name} Points:`}
            avatarId={werewolf?.avatarId ?? ''}
            showLabel={false}
            descriptionText={`
          <div>
            <div class="text-7xl text-center font-bold text-gray-100 mb-3">${gameState.currentState.finalBattle?.werewolfScore}</div>
            <div class="text-center font-bold ${werewolfModifier >= 0 ? 'text-green-500' : 'text-red-500'}">
              Roll Modifier: ${werewolfModifier}
            </div>
          </div>`}
          />
        }
      />
    );
  } else {
    return <div />;
  }
}
