import { GameState } from 'Model';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlayerImage } from './PlayerImage';

export type GameMasterCardProps = {
  gameState: GameState;
};

export function GameMasterCard(props: GameMasterCardProps) {
  const { gameState } = props;

  return (
    <div className="flex justify-between rounded-xl col-span-3 bg-green-900 border border-green-600 p-3 mb-6 lg:mb-0">
      <div className="flex">
        <div className={`rounded-full overflow-hidden mr-3 border-4 border-green-600 bg-white self-center`}>
          <PlayerImage name={gameState.gameMaster} hasImageSrc={gameState.gameMasterHasImageSrc} avatarId={gameState.gameMasterAvatarId} classes="h-12 w-12" />
        </div>
        <div className="self-center text-left mr-3">
          <div className={'font-bold text-yellow-600 text-xl'}>Game Master</div>
          <div className="font-medium text-gray-300 text-lg">{gameState.gameMaster}</div>
        </div>
      </div>
    </div>
  );
}
