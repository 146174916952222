import { GameRole, GameState, Player } from './Model';

export function getCurrentPlayer(name: string, players: Player[]) {
  return players.find((player) => {
    return player.name === name;
  });
}

export function getCurrentRound(gameState: GameState) {
  gameState.currentState.gameRounds.sort((a, b) => {
    return a.timestamp < b.timestamp ? 1 : -1;
  });
  return gameState.currentState.gameRounds[0];
}

export function getCurrentFinalBattleRound(gameState: GameState) {
  if (gameState.currentState.finalBattle) {
    gameState.currentState.finalBattle.finalBattleRounds.sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : -1;
    });
    return gameState.currentState.finalBattle.finalBattleRounds[0];
  } else {
    return undefined;
  }
}

export function getWerewolf(gameState: GameState) {
  return gameState.currentState.players.find((player) => {
    return player.role === GameRole.Werewolf;
  });
}

export function getWerewolves(gameState: GameState) {
  return gameState.currentState.players.filter((player) => {
    return player.role === GameRole.Werewolf;
  });
}

export function getSeer(gameState: GameState) {
  return gameState.currentState.players.find((player) => {
    return player.role === GameRole.Seer;
  });
}

export function getWitch(gameState: GameState) {
  return gameState.currentState.players.find((player) => {
    return player.role === GameRole.Witch;
  });
}

export function getFinalVillager(gameState: GameState) {
  return gameState.currentState.players.find((player) => {
    return player.role !== GameRole.Werewolf && player.isAlive;
  });
}

export function getVillagerChampion(gameState: GameState) {
  return gameState.currentState.players.find((player) => {
    return (player.isChampion = true);
  });
}

export function getPlayerByName(gameState: GameState, name: string) {
  return gameState.currentState.players.find((player) => {
    return player.name === name;
  });
}

export function isGameMaster(name: string, gameState: GameState) {
  return name === gameState.gameMaster;
}

export function isWerewolf(name: string, players: Player[]) {
  return players.find((player) => {
    return player.name === name && player.role === GameRole.Werewolf;
  });
}

export function isPlayerSeer(name: string, players: Player[]) {
  let seer = players.find((player) => {
    return player.name === name && player.role === GameRole.Seer;
  });

  return seer !== undefined;
}

export function isWitch(name: string, players: Player[]) {
  return players.find((player) => {
    return player.name === name && player.role === GameRole.Witch;
  });
}
