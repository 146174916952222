import { GamePhase } from 'Model';
import { PlayerList, GameUIContainer, Button } from 'components/shared';
import { useContext } from 'react';
import { GameContext } from 'GameContext';

export type OpenPhaseProps = {
  handleStartGame: Function;
  handleGenerateDummyPlayers: Function;
};

export default function OpenPhase(props: OpenPhaseProps) {
  const { handleStartGame, handleGenerateDummyPlayers } = props;
  const { gameState, name } = useContext(GameContext)!;

  if (gameState && name) {
    const isGameMaster = name === gameState.gameMaster;
    return (
      <div>
        <GameUIContainer
          headerText={`Hello, ${name}`}
          subHeaderText="Waiting for other villagers to arrive . . ."
          headerButton={
            gameState &&
            gameState?.currentState.players.length >= 5 &&
            isGameMaster &&
            gameState.currentState.gamePhase === GamePhase.OPEN && <Button buttonText="Start Game" onClick={() => handleStartGame()} fullWidth={true} />
          }
          isNight={false}
          leftColumn={isGameMaster && process.env.REACT_APP_ENV !== 'prod' && <Button buttonText="Generate Dummy Players" onClick={() => handleGenerateDummyPlayers()} />}
          rightColumn={<PlayerList gameState={gameState} onPlayerSelect={() => {}} filterCurrentPlayer={false} name={name} />}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
}
