import { GameState } from 'Model';
import { getSeer, getWerewolves, getWitch } from 'Util';
import PlayerListItem from './PlayerListItem';

export type SpecialPlayersProps = {
  gameState: GameState;
};

export default function SpecialPlayers(props: SpecialPlayersProps) {
  const werewolves = getWerewolves(props.gameState);
  const seer = getSeer(props.gameState);
  const witch = getWitch(props.gameState);

  return (
    <div className="w-full">
      <div className="mb-3 font-bold text-lg text-gray-100">Special Villagers</div>
      <div className="grid grid-cols-1 gap-3 w-full lg:w-80">
        {werewolves.map((werewolf) => (
          <PlayerListItem gameId={props.gameState.gameId} player={werewolf} description="Werewolf" key={werewolf.name} />
        ))}
        {seer && <PlayerListItem gameId={props.gameState.gameId} player={seer} description="Seer" key={seer.name} />}
        {witch && <PlayerListItem gameId={props.gameState.gameId} player={witch} description="Witch" key={witch.name} />}
      </div>
    </div>
  );
}
