import { CheckCircleIcon } from '@heroicons/react/solid';
import GuessIcon from 'assets/GuessIcon';
import HeartIcon from 'assets/HeartIcon';
import SkullIcon from 'assets/SkullIcon';
import GhostIcon from 'assets/GhostIcon';
import { GamePhase, GameState, PlayerVote } from 'Model';
import { Fragment } from 'react';
import { getPlayerByName, isWitch, isGameMaster, isPlayerSeer, isWerewolf } from 'Util';
import PlayerListItem from './PlayerListItem';

export type PlayerListProps = {
  gameState: GameState;
  onPlayerSelect: Function;
  name: string;
  filterCurrentPlayer: boolean;
};

export default function PlayerList(props: PlayerListProps) {
  const { gameState, onPlayerSelect, name, filterCurrentPlayer } = props;

  let players = gameState.currentState.players;
  let currentPlayer = getPlayerByName(gameState, name);
  let showGameMasterMenu = gameState.gameMaster === name;

  let alivePlayers = gameState.currentState.players.filter((player) => {
    if (filterCurrentPlayer) {
      return player.isAlive && player.name !== name;
    } else {
      return player.isAlive;
    }
  });

  let deadPlayers = gameState.currentState.players.filter((player) => {
    return !player.isAlive;
  });

  gameState.currentState.gameRounds.sort((a, b) => {
    return a.timestamp < b.timestamp ? 1 : -1;
  });
  let currentRound = gameState.currentState.gameRounds[0];

  let playerVote: PlayerVote | undefined;
  if (gameState.currentState.gamePhase === GamePhase.VOTING && currentRound) {
    playerVote = currentRound.votes.find((playerVote) => {
      return playerVote.name === name;
    });
  }

  return (
    <div>
      {alivePlayers.length > 0 && <div className="font-bold text-lg text-gray-100 mb-3">Survivors</div>}
      <div className="grid grid-cols-1 gap-3 lg:w-80">
        {alivePlayers.map((player, index) => (
          <PlayerListItem
            gameId={gameState.gameId}
            player={player}
            key={player.name + '' + index}
            onClick={() => {
              if (player.name !== name) {
                onPlayerSelect(player.name);
              }
            }}
            cursorClass={player.name !== currentPlayer?.name && player.isAlive ? 'cursor-pointer' : 'cursor-not-allowed'}
            icon={
              <div className="flex gap-3">
                {gameState.currentState.gamePhase === GamePhase.NIGHT && (
                  <Fragment>
                    {currentRound.ghostVotes[player.name] && (
                      <div className="grid">
                        <div className="col-start-1 row-start-1 h-12 w-12 text-gray-100">
                          <GhostIcon />
                        </div>
                        <div className="col-start-1 row-start-1 text-center mt-3 font-bold">{currentRound.ghostVotes[player.name]}</div>
                      </div>
                    )}
                    {((isWitch(name, players) && currentRound.witchGuarded === player.name) || (isGameMaster(name, gameState) && currentRound.witchGuarded === player.name)) && (
                      <div className="h-10 w-10 text-green-600">
                        <HeartIcon />
                      </div>
                    )}

                    {((isPlayerSeer(name, players) && currentRound.seerGuess === player.name) || (isGameMaster(name, gameState) && currentRound.seerGuess === player.name)) && (
                      <div className="h-10 w-10 text-orange-600">
                        <GuessIcon />
                      </div>
                    )}
                    {((isWerewolf(name, players) && currentRound.werewolfVictim === player.name && currentPlayer?.isAlive) ||
                      (isGameMaster(name, gameState) && currentRound.werewolfVictim === player.name)) && (
                      <div className="h-10 w-10 text-red-600">
                        <SkullIcon />
                      </div>
                    )}
                  </Fragment>
                )}
                {gameState.currentState.gamePhase === GamePhase.VOTING && playerVote && player.name === playerVote.guessName && (
                  <div className="h-8 w-8 text-green-500">
                    <CheckCircleIcon />
                  </div>
                )}
              </div>
            }
            showGameMasterMenu={showGameMasterMenu}
          />
        ))}
        {deadPlayers && deadPlayers.length > 0 && (
          <div className="mt-6">
            <div className="font-bold text-gray-100 text-lg mb-3">Deceased/Exiled</div>
            <div className="grid grid-cols-1 gap-3">
              {deadPlayers.map((player, index) => (
                <PlayerListItem
                  gameId={gameState.gameId}
                  player={player}
                  key={player.name + '' + index}
                  cursorClass={player.name !== currentPlayer?.name && player.isAlive ? 'cursor-pointer' : 'cursor-not-allowed'}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
