import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Game from './components/Game';
import WolfIcon from './assets/WolfIcon';

function App() {
  return (
    <div>
      <header className="bg-gray-900 flex">
        <WolfIcon />
        <a href="/" className="text-xl font-medium text-gray-50 self-center">
          WhoWolfYouWolf
        </a>
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:gameId" element={<Game />} />
      </Routes>
    </div>
  );
}

export default App;
