import { GamePhase, GameRole } from 'Model';
import { getCurrentPlayer, getWerewolves, isGameMaster } from 'Util';
import { DeadState } from 'components/phases';
import { Button, SpecialPlayers, PlayerCard, PlayerList, GameUIContainer, WolfPackVictimChoice } from 'components/shared';
import NightSeerExplainer from './NightSeerExplainer';
import NightWitchExplainer from './NightWitchExplainer';
import NightVillagerExplainer from './NightVillagerExplainer';
import WolfChat from 'components/shared/WolfChat';
import { useContext } from 'react';
import { GameContext } from 'GameContext';
import NightGhostExplainer from './NightGhostExplainer';
import NightWerewolfExplainer from './NightWerewolfExplainer';

export type NightPhaseProps = {
  handleSelectVictim: Function;
  handleSelectSeerGuess: Function;
  handleSelectPatient: Function;
  handleNextPhase: Function;
  handleSendWolfChatMessage: Function;
  handleGhostSelection: Function;
};

export default function NightPhase(props: NightPhaseProps) {
  const { gameState, name } = useContext(GameContext)!;

  if (gameState && name) {
    const { handleSelectVictim, handleSelectSeerGuess, handleSelectPatient, handleNextPhase, handleSendWolfChatMessage, handleGhostSelection } = props;
    const currentPlayer = getCurrentPlayer(name, gameState.currentState.players);

    if (currentPlayer && currentPlayer.isAlive) {
      let headerText = '';
      let subHeaderText = '';
      let actionHandler: Function = () => {};
      let centerColumn: React.ReactNode | undefined;

      switch (currentPlayer?.role) {
        case GameRole.Werewolf:
          headerText = 'Night has fallen!';
          subHeaderText = 'You have no control over your dark hunger.';
          actionHandler = handleSelectVictim;
          centerColumn = (
            <>
              {getWerewolves(gameState).length > 1 ? (
                <>
                  <WolfPackVictimChoice extraClasses="mb-6" gameState={gameState} />
                  <WolfChat gameState={gameState} onSave={handleSendWolfChatMessage} />
                </>
              ) : (
                <NightWerewolfExplainer />
              )}
            </>
          );
          break;
        case GameRole.Seer:
          headerText = 'Night has fallen!';
          subHeaderText = 'Use your powers to find the werewolf!';
          actionHandler = handleSelectSeerGuess;
          centerColumn = <NightSeerExplainer />;
          break;
        case GameRole.Witch:
          headerText = 'Night has fallen!';
          subHeaderText = 'Your friends need your help!';
          actionHandler = handleSelectPatient;
          centerColumn = <NightWitchExplainer />;
          break;
        default:
          headerText = 'Beware Villager!';
          subHeaderText = 'Night is a dangerous place!';
          centerColumn = <NightVillagerExplainer />;
      }

      return (
        <GameUIContainer
          headerText={headerText}
          subHeaderText={subHeaderText}
          isNight={true}
          leftColumn={
            currentPlayer && (
              <PlayerCard
                player={currentPlayer}
                primaryText={currentPlayer.name}
                secondaryText={currentPlayer.role}
                avatarId={currentPlayer.avatarId}
                descriptionText={currentPlayer.roleDescription}
                showLabel={true}
              />
            )
          }
          centerColumn={centerColumn}
          rightColumn={<PlayerList gameState={gameState} onPlayerSelect={actionHandler} filterCurrentPlayer={false} name={name} />}
        />
      );
    } else if (isGameMaster(name, gameState)) {
      return (
        <GameUIContainer
          headerText="Night has fallen."
          subHeaderText="The werewolf, seer and witch are out and about."
          headerButton={<Button buttonText="Awaken The Villagers" onClick={() => handleNextPhase(GamePhase.DAY)} fullWidth={true} />}
          isNight={true}
          leftColumn={<SpecialPlayers gameState={gameState} />}
          rightColumn={<PlayerList gameState={gameState} onPlayerSelect={() => {}} filterCurrentPlayer={true} name={name} />}
        />
      );
    } else {
      return (
        <GameUIContainer
          headerText="Bummer, you're dead!"
          subHeaderText="On the plus side, you're a ghost!"
          headerButton={undefined}
          isNight={true}
          leftColumn={
            currentPlayer && (
              <PlayerCard
                player={currentPlayer}
                primaryText={currentPlayer.name}
                secondaryText={currentPlayer.role}
                avatarId={currentPlayer.avatarId}
                descriptionText={`<div class="text-center">${currentPlayer.roleDescription}</div>`}
                showLabel={true}
              />
            )
          }
          centerColumn={<NightGhostExplainer />}
          rightColumn={<PlayerList gameState={gameState} onPlayerSelect={handleGhostSelection} filterCurrentPlayer={true} name={name} />}
        />
      );
    }
  } else {
    return <div></div>;
  }
}
