import { GameState } from 'Model';
import { getCurrentRound, getPlayerByName } from 'Util';

export type WolfPackVictimChoiceProps = {
  gameState: GameState;
  extraClasses?: string;
};

export default function WolfPackVictimChoice(props: WolfPackVictimChoiceProps) {
  const { gameState, extraClasses } = props;

  let currentRound = getCurrentRound(gameState);
  let wolfPackVictimChoices = currentRound.packVictimChoices;
  let choicesUI = wolfPackVictimChoices.map((wolfPackVictimChoice) => {
    let player = getPlayerByName(gameState, wolfPackVictimChoice.name);

    if (player?.isAlive) {
      if (wolfPackVictimChoice.choice) {
        let wolfPackVictim = getPlayerByName(gameState, wolfPackVictimChoice.choice);
        return (
          <div className="p-6">
            <div className="font-bold text-lg text-gray-100 mb-3 text-center">{player ? player.name : 'Unknonwn'} Choice</div>
            <div className="bg-gray-900 p-3 rounded-xl">
              <div className="font-bold text-gray-300 text-center">{wolfPackVictim?.name}</div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="p-6">
            <div className="font-bold text-lg text-gray-100 mb-3 text-center">{player ? player.name : 'Unknonwn'} Choice</div>
            <div className="font-bold text-red-500 bg-gray-900 p-3 rounded-xl self-center text-center">Waiting</div>
          </div>
        );
      }
    } else {
      return (
        <div className="p-6">
          <div className="font-bold text-lg text-gray-100 mb-3 text-center">{player ? player.name : 'Unknonwn'} Choice</div>
          <div className="font-bold text-red-500 bg-gray-900 p-3 rounded-xl self-center text-center">Dead!</div>
        </div>
      );
    }
  });

  return (
    <div className={extraClasses ?? ''}>
      <div className="font-bold text-lg text-gray-100 mb-3">Pack Victim Choices</div>
      <div className="rounded-xl border border-gray-700 bg-gray-800 grid grid-cols-2">
        {choicesUI.map((choiceUI) => (
          <div>{choiceUI}</div>
        ))}
      </div>
    </div>
  );
}
