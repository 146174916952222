import { GamePhase, Player } from 'Model';
import { getCurrentRound, getPlayerByName, getWerewolves } from 'Util';
import { useContext, useState } from 'react';
import { Button, SpecialPlayers, PlayerCard, PlayerList, GameUIContainer } from 'components/shared';
import { GameContext } from 'GameContext';

export type VoteRevealPhaseProps = {
  handleNextPhase: Function;
};

export default function VoteRevealPhase(props: VoteRevealPhaseProps) {
  const { gameState, name } = useContext(GameContext);
  let currentRound = getCurrentRound(gameState!);
  let isTie = currentRound.isTie;

  let player: Player | undefined;
  player = getPlayerByName(gameState!, currentRound.voteResults);

  let [open, setOpen] = useState(player !== undefined || isTie);

  if (gameState && name) {
    const { handleNextPhase } = props;

    let currentPlayer = getPlayerByName(gameState, name);

    let isGameMaster = name === gameState.gameMaster;
    let isWerewolf = false;
    const werewolves = getWerewolves(gameState);

    isWerewolf =
      werewolves.find((werewolf) => {
        return werewolf.name === currentRound.voteResults;
      }) !== undefined;

    let descriptionText = `The werewolf is still among us.`;
    if (isWerewolf) {
      descriptionText = `The best was among us all along! Cast the beast into the bottomeless pit!`;
    }

    let nextPhase = gameState.currentState.finalBattle ? GamePhase.FINAL_BATTLE : GamePhase.NEXT_ROUND;
    let buttonText = gameState.currentState.finalBattle ? 'Start Final Battle' : 'Start Night';

    return (
      <div>
        <GameUIContainer
          headerText="The vote has been cast!"
          subHeaderText={isTie ? "It's a tie!" : 'Goodbye friend.'}
          headerButton={isGameMaster && <Button buttonText={buttonText} onClick={() => handleNextPhase(nextPhase)} fullWidth={true} />}
          isNight={false}
          openCardOverlay={open}
          onCloseCardOverlay={() => {
            if (isGameMaster) {
              setOpen(false);
            }
          }}
          overlayContent={
            isTie ? (
              <PlayerCard
                primaryText="It's a tie!"
                avatarId="spring"
                descriptionText='<div class="text-center">No one will be exiled from the village today, but the werewolf is still out there.</div>'
                showLabel={false}
              />
            ) : (
              <PlayerCard
                player={player}
                primaryText={isWerewolf ? 'A Werewolf Is Discovered!' : 'Exiled To The Wilderness'}
                secondaryText={player?.name}
                avatarId={player?.avatarId ?? ''}
                descriptionText={`<div class="text-center">${descriptionText}</div>`}
                showLabel={false}
              />
            )
          }
          leftColumn={
            <div>
              {currentPlayer && (
                <PlayerCard
                  player={currentPlayer}
                  primaryText={currentPlayer.name}
                  secondaryText={currentPlayer.role}
                  avatarId={currentPlayer.avatarId}
                  descriptionText={`<div class="text-center">${currentPlayer.roleDescription}</div>`}
                  showLabel={true}
                />
              )}
              {isGameMaster && <SpecialPlayers gameState={gameState} />}
            </div>
          }
          rightColumn={<PlayerList gameState={gameState} onPlayerSelect={() => {}} filterCurrentPlayer={false} name={name} />}
        />
      </div>
    );
  } else {
    return <div />;
  }
}
