export type ButtonProps = {
  buttonText: string;
  onClick: Function;
  fullWidth?: boolean;
};

export default function Button(props: ButtonProps) {
  const { buttonText, onClick, fullWidth = false } = props;
  return (
    <div>
      <button className={'bg-red-500 hover:bg-red-600 text-white rounded-full font-bold p-3 ' + (fullWidth ? 'w-full' : 'lg:w-80')} onClick={() => onClick()}>
        {buttonText}
      </button>
    </div>
  );
}
